<div class="" *ngIf="!isLoggedIn">
  <nav class="navbar navbar-expand navbar-light middle-header pl-3 pr-3">
    <div class="container">
      <a class="navbar-brand" routerLink="/home">
        <img style="top:0" class="logoImage" src="assets/latest/exobe-logo.svg" alt="logo" routerlinkActive />
      </a>

      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
        <!-- <div>
          <input type="search" name="Search" id="inp_search" placeholder="Search here">
          <button class="btn btn_search_group" type="button">
            <img src="assets/latest/search.png" alt="Search">
          </button>
        </div> -->
        <div class="navbar-nav">
          <a class="nav-item nav-link" (click)="menuNavigateTo('home')"
            [ngClass]="{ active: activebar == 'home' || activebar == 'retailer-home' }"
            *ngIf="loggedInUserType == 'CUSTOMER' || !isLoggedIn" routerLink="/home">Home</a>
          <img src="assets/latest/header-line.svg" class="header-line" alt="" />
          <a class="nav-item nav-link active" *ngIf="
              isLoggedIn &&
              loggedInUserType == 'SERVICE_PROVIDER' &&
              SharingData &&
              userRequestStatus == 'APPROVED'
            " routerLink="/service-order-detail">Home</a>

          <a class="nav-item nav-link active" *ngIf="
              isLoggedIn &&
              loggedInUserType == 'RETAILER' &&
              SharingData &&
              userRequestStatus == 'APPROVED'
            " routerLink="/retailer-home">Home</a>
          <a class="nav-item nav-link" routerLink="/contactUs">Contact Us</a>
          <img src="assets/latest/header-line.svg" class="header-line" alt="" />
          <div *ngIf="!isLoggedIn" class="nav-item dropdown">
            <a class="nav-link" style="padding-left: 5px" data-toggle="dropdown">
              Log In
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="loginNavigate('CUSTOMER')">Customer Login</a>
              <a class="dropdown-item" (click)="loginNavigate('RETAILER')">Retailer Login</a>
              <a class="dropdown-item" (click)="loginNavigate('SERVICE_PROVIDER')"
                style="border-bottom: 2px solid #bf1e2e !important">Service Provider Login</a>
            </div>
          </div>

          <img src="assets/latest/header-line.svg" class="header-line" alt="" />
        </div>
        <div class="navbar-nav">
          <div class="navbar-social-icon">
            <div *ngIf="!isLoggedIn" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" style="color: #bf1e2e; padding-left: 5px" data-toggle="dropdown">
                <img src="assets/latest/usericon.svg" class="first-profile-ico" alt="" />
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="loginNavigate('CUSTOMER')">Customer Login</a>
                <a class="dropdown-item" (click)="loginNavigate('RETAILER')">Retailer Login</a>
                <a class="dropdown-item" (click)="loginNavigate('SERVICE_PROVIDER')"
                  style="border-bottom: 2px solid #bf1e2e !important">Service Provider Login</a>
              </div>
            </div>
            <div *ngIf="
                isLoggedIn &&
                (loggedInUserType == 'CUSTOMER' ||
                  loggedInUserType == 'RETAILER') &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass">
                <i class="fa fa-shopping-bag" data-toggle="tooltip" data-placement="bottom" title="My Cart"
                  aria-hidden="true" (click)="menuNavigateTo('addtoCart-customer')"
                  [ngClass]="{ active: activebar == 'addtoCart-customer' }"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                (loggedInUserType == 'CUSTOMER' ||
                  loggedInUserType == 'RETAILER') &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass">
                <i class="fa fa-heart" data-toggle="tooltip" data-placement="bottom" title="Wishlist"
                  (click)="menuNavigateTo('wishlist-customer')"
                  [ngClass]="{ active: activebar == 'wishlist-customer' }"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'RETAILER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass">
                <i class="fa fa-first-order" data-toggle="tooltip" data-placement="bottom" title="Orders"
                  (click)="menuNavigateTo('retailer-order-list')"
                  [ngClass]="{ active: activebar == 'retailer-order-list' }"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'RETAILER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass">
                <img src="assets/img/product.png" data-toggle="tooltip" data-placement="bottom" title="Products"
                  style="width: 25px" (click)="menuNavigateTo('retailer-product-list')"
                  [ngClass]="{ active: activebar == 'retailer-product-list' }" />
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'RETAILER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                <img src="assets/img/deal1.png" data-toggle="tooltip" data-placement="bottom" title="Deals"
                  style="width: 34px" [ngClass]="{ active: activebar == 'retailer-deal-list' }" />
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('retailer-deal-list')">Deals to customer</a>
                <a class="dropdown-item" (click)="menuNavigateTo('customer-product-deal-list')">Deals from
                  wholesalers</a>
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service')">Deals on bookings</a>
              </div>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'CUSTOMER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass" data-toggle="tooltip" data-placement="bottom" title="Bookings">
                <i class="fa fa-wrench" (click)="menuNavigateTo('customer-service-list')"
                  [ngClass]="{ active: activebar == 'customer-service-list' }"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'CUSTOMER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass" data-toggle="tooltip" data-placement="bottom" title="Categories">
                <i class="fa fa-list-alt" (click)="menuNavigateTo('customer-category-list')"
                  [ngClass]="{ active: activebar == 'customer-category-list' }"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'CUSTOMER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                <img src="assets/img/deal1.png" data-toggle="tooltip" data-placement="bottom" title="Deals"
                  style="width: 34px" />
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('deal-list')">Deals on products</a>
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service')">Deals on bookings</a>
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service-list')">My Bookings History</a>
              </div>
            </div>

            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'RETAILER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                <i class="fa fa-history" data-toggle="tooltip" data-placement="bottom" title="Payment History"
                  aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('reatiler-payment-customer')">payment from customer</a>
                <a class="dropdown-item" (click)="menuNavigateTo('reatiler-payment-wholesaler')">Payment to
                  wholesalers</a>
              </div>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'SERVICE_PROVIDER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass" style="cursor: pointer" data-toggle="tooltip" data-placement="bottom"
                title="My Bookings">
                <img style="width: 23px" (click)="menuNavigateTo('service-provider-request-services')"
                  src="assets/img/request.png" />
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'SERVICE_PROVIDER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass" data-toggle="tooltip" data-placement="bottom" title="Deals to customers">
                <i class="fa fa-gift" (click)="menuNavigateTo('service-provider-deals-to-customer')"
                  aria-hidden="true"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'SERVICE_PROVIDER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass" data-toggle="tooltip" data-placement="bottom"
                title="Payment From Customers">
                <i class="fa fa-history" (click)="
                    menuNavigateTo('service-provider-payment-from-customers')
                  " aria-hidden="true"></i>
              </a>
            </div>
            <div *ngIf="
                isLoggedIn &&
                loggedInUserType == 'SERVICE_PROVIDER' &&
                SharingData &&
                userRequestStatus == 'APPROVED'
              " class="nav-item">
              <a class="nav-link iconClass" data-toggle="tooltip" data-placement="bottom" title="Services">
                <i class="fa fa-wrench" (click)="menuNavigateTo('services')"></i>
              </a>
            </div>

            <div *ngIf="isLoggedIn" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                <i class="fa fa-user first-profile-ico" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu" *ngIf="userRequestStatus == 'APPROVED'">
                <a class="dropdown-item" *ngIf="SharingData" routerLink="/customer-profile">My Profile</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-order-history">Order History</a>



                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-product-enquiry">Product Enquiry</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-address-list">Address</a>
                <a class="dropdown-item" (click)="logOutModal()">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
<header *ngIf="isLoggedIn" [ngClass]="{ 'd-flex': !isMobile, 'd-block': isMobile }">
  <div class="container">
    <nav>
      <div class="left-container d-flex align-items-center justify-content-between">
        <ng-container *ngIf="flagMe">
          <a class="navbar-brand" *ngIf="loggedInUserType == 'CUSTOMER' || !isLoggedIn" (click)="navigateHome('/home')">
            <img class="logoImage" src="assets/latest/exobe-logo.svg" alt="logo" routerlinkActive />
          </a>
          <a class="navbar-brand" *ngIf="isLoggedIn && loggedInUserType == 'RETAILER'"
            (click)="navigateHome('/retailer-home')">
            <img class="logoImage" src="assets/latest/exobe-logo.svg" alt="logo" routerlinkActive />
          </a>
          <a class="navbar-brand" *ngIf="isLoggedIn && loggedInUserType == 'SERVICE_PROVIDER'"
            (click)="navigateHome('/home')">
            <img class="logoImage" src="assets/latest/exobe-logo.svg" alt="logo" routerlinkActive />
          </a>
        </ng-container>
        <ng-container *ngIf="!flagMe">
          <a class="navbar-brand" style="margin-bottom: 38px;" (click)="navigateHome('/home')">
            <img class="logoImage" src="assets/latest/exobe-logo.svg" alt="logo" routerlinkActive />
          </a>
        </ng-container>

      </div>
      <div class="right-container d-flex justify-content-end">
        <div class="menu-item">
          <div class="menu-text">
            <p [routerLink]="['/contactUs']">Help</p>
          </div>
          <img src="assets/latest/header-line.svg" class="header-line" alt="" />
        </div>
        <div class="menu-item">
          <div class="menu-text">
            <p (click)="navigateToAccount()">Account</p>
          </div>
          <img src="assets/latest/header-line.svg" class="header-line" alt="" />
        </div>
        <div class="menu-item" *ngIf="
            isLoggedIn &&
            (loggedInUserType == 'CUSTOMER' ||
              loggedInUserType == 'RETAILER') &&
            SharingData &&
            userRequestStatus == 'APPROVED'
          ">
          <div class="menu-text" (click)="menuNavigateTo('wishlist-customer')"
            [ngClass]="{ active: activebar == 'wishlist-customer' }">
            <p>Wishlist</p>
          </div>
          <img src="assets/latest/header-line.svg" class="header-line" alt="" />
        </div>
        <div class="menu-item" *ngIf="
            isLoggedIn &&
            (loggedInUserType == 'CUSTOMER' ||
              loggedInUserType == 'RETAILER') &&
            SharingData &&
            userRequestStatus == 'APPROVED'
          ">
          <div class="cart-logo" (click)="menuNavigateTo('addtoCart-customer')"
            [ngClass]="{ active: activebar == 'addtoCart-customer' }">
            <img src="assets/latest/cart.png" alt="" srcset="" />
            <!-- <div class="cart-containerp">
              <img src="assets/latest/cart.png" alt="Cart" class="cart-iconp">
              <span class="item-countp">3</span>
            </div> -->
          </div>
        </div>
      </div>
    </nav>
    <nav class="justify-content-end" style="width: 99%">
      <div class="main-menu-container">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('home')"
            [ngClass]="{ active: activebar == 'home' || activebar == 'retailer-home' }"
            *ngIf="loggedInUserType == 'CUSTOMER'" routerLink="/home">
            <p>Home</p>
          </div>
          <div class="main-menu-text" *ngIf="
              isLoggedIn &&
              loggedInUserType == 'SERVICE_PROVIDER' &&
              SharingData &&
              userRequestStatus == 'APPROVED'
            " routerLink="/service-order-detail">
            <p>Home</p>
          </div>
          <div class="main-menu-text" *ngIf="
              isLoggedIn &&
              loggedInUserType == 'RETAILER' &&
              SharingData &&
              userRequestStatus == 'APPROVED'
            " routerLink="/retailer-home">
            <p>Home</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'home' || activebar == 'retailer-home'"></div>
      </div>

      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'RETAILER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('retailer-product-list')"
            [ngClass]="{ active: activebar == 'retailer-product-list' }">
            <p>Product</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'retailer-product-list'"></div>
      </div>
      <!-- service provider -->
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'SERVICE_PROVIDER' &&
          SharingData &&
          userRequestStatus == 'APPROVED' && !isSubService
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('service-provider-request-services')" [ngClass]="{
              active: activebar == 'service-provider-request-services'
            }">
            <p>Requested Service</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'service-provider-request-services'"></div>
      </div>
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'SERVICE_PROVIDER' &&
          SharingData &&
          userRequestStatus == 'APPROVED' 

        ">
        <div class="main-menu-item">
          <div class="main-menu-text" [routerLink]="['/transaction-history']" [ngClass]="{
              active: activebar == 'transaction-history'
            }">
            <p>Transaction History</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'service-provider-request-services'"></div>
      </div>



      <div class="main-menu-container" *ngIf="
      isLoggedIn &&
      loggedInUserType == 'RETAILER' &&
      SharingData &&
      userRequestStatus == 'APPROVED'
    ">
        <div class="main-menu-item">
          <div class="main-menu-text" [routerLink]="['/transaction-history']" [ngClass]="{
              active: activebar == 'transaction-history'
            }">
            <p>Transaction History</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'service-provider-request-services'"></div>
      </div>
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'SERVICE_PROVIDER' &&
          SharingData &&
          userRequestStatus == 'APPROVED' && !isSubService
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('service-provider-deals-to-customer')" [ngClass]="{
              active: activebar == 'service-provider-deals-to-customer'
            }">
            <p>Deals</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'service-provider-deals-to-customer'"></div>
      </div>
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'SERVICE_PROVIDER' &&
          SharingData &&
          userRequestStatus == 'APPROVED' && !isSubService
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('service-provider-payment-from-customers')" [ngClass]="{
              active: activebar == 'service-provider-payment-from-customers'
            }">
            <p>Payment</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'service-provider-payment-from-customers'"></div>
      </div>
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'SERVICE_PROVIDER' &&
          SharingData &&
          userRequestStatus == 'APPROVED' && !isSubService
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('services')"
            [ngClass]="{ active: activebar == 'services' }">
            <p>Services</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'services'"></div>
      </div>

      <!-- order start -->
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'RETAILER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('retailer-order-list')"
            [ngClass]="{ active: activebar == 'retailer-order-list' }">
            <p>Orders</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'retailer-order-list'"></div>
      </div>
      <!-- order end -->
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'CUSTOMER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text" (click)="menuNavigateTo('customer-category-list')"
            [ngClass]="{ active: activebar == 'customer-category-list' }">
            <p>Category</p>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'customer-category-list'"></div>
      </div>

      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'CUSTOMER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text">
            <!-- <p>Bookings</p> -->
            <div class="nav-item dropdown" [ngClass]="{ active: activebar == 'customer-service-list' }">
              <a class="nav-link dropdown-toggle dealsRetailer" data-toggle="dropdown">
                Bookings
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('customer-service-list')">Booking Categories</a>
                <!-- <a class="dropdown-item" (click)="menuNavigateTo('deal-service')">Deals on bookings</a> -->
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service-list')">My Bookings History</a>
              </div>
            </div>
          </div>
        </div>
        <div class="border underline" *ngIf="
          activebar == 'customer-service-list' ||
          activebar == 'deal-service-list'
          "></div>
      </div>
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'RETAILER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text">
            <!-- <p>Bookings</p> -->
            <div class="nav-item dropdown" [ngClass]="{ active: activebar == 'customer-service-list' }">
              <a class="nav-link dropdown-toggle dealsRetailer" data-toggle="dropdown">
                Bookings
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('customer-service-list')">Category
                </a>
                <!-- <a class="dropdown-item" (click)="menuNavigateTo('deal-service')">Deals on bookings</a> -->
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service-list')">My Bookings History</a>
              </div>
            </div>
          </div>
        </div>
        <div class="border underline" *ngIf="
          activebar == 'customer-service-list' ||
          activebar == 'deal-service-list'
          "></div>
      </div>
      <!-- reatiler deals  -->
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'RETAILER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text">
            <div class="nav-item dropdown" [ngClass]="{ active: activebar == 'retailer-deal-list' }">
              <a class="nav-link dropdown-toggle dealsRetailer" data-toggle="dropdown">
                Deals
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('retailer-deal-list')">Deals to customer</a>
                <a class="dropdown-item" (click)="menuNavigateTo('customer-product-deal-list')">Deals from
                  wholesalers</a>
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service')">Deals on bookings</a>
              </div>
            </div>
          </div>
        </div>
        <div class="border underline" *ngIf="
          activebar == 'retailer-deal-list' ||
          activebar == 'customer-product-deal-list' ||
          activebar == 'deal-service'
          "></div>
      </div>
      <!-- payment reatiler start -->
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'RETAILER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text">
            <div class="nav-item dropdown" [ngClass]="{ active: activebar == 'retailer-deal-list' }">
              <a class="nav-link dropdown-toggle dealsRetailer" data-toggle="dropdown">
                Payment
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('reatiler-payment-customer')">payment from customer</a>
                <a class="dropdown-item" (click)="menuNavigateTo('reatiler-payment-wholesaler')">Payment to
                  wholesalers</a>
              </div>
            </div>
          </div>
        </div>
        <div class="border underline" *ngIf="
          activebar == 'reatiler-payment-customer' ||
          activebar == 'reatiler-payment-wholesaler'
          "></div>
      </div>
      <!-- payment reatiler end  -->
      <!-- customner deals -->
      <div class="main-menu-container" *ngIf="
          isLoggedIn &&
          loggedInUserType == 'CUSTOMER' &&
          SharingData &&
          userRequestStatus == 'APPROVED'
        ">
        <div class="main-menu-item">
          <div class="main-menu-text">
            <div class="nav-item dropdown" [ngClass]="{ active: activebar == 'deal-list' }">
              <a class="nav-link dropdown-toggle dealsRetailer" data-toggle="dropdown">
                Deals
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="menuNavigateTo('deal-list')">Deals on products</a>
                <a class="dropdown-item" (click)="menuNavigateTo('deal-service')">Deals on bookings</a>
              </div>
            </div>
          </div>
        </div>
        <div class="border underline" *ngIf="activebar == 'deal-list' || activebar == 'deal-service'"></div>
      </div>
      <div class="main-menu-container">
        <div class="main-menu-item">
          <div class="main-menu-text d-flex align-items-center">
            <!-- <p class="userName">{{profileData?.firstName}}</p> -->
            <div *ngIf="isLoggedIn && flagMe " class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                <!-- <i class="fa fa-user first-profile-ico" aria-hidden="true"></i> -->
                <img class="profileImageBox" [src]="profileData?.profilePic || 'assets/latest/profile.png'" alt="" />
              </a>
              <div class="dropdown-menu" *ngIf="userRequestStatus == 'APPROVED'">
                <a class="dropdown-item" *ngIf="SharingData" routerLink="/customer-profile">My Profile</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-order-history">Order History</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/intrested-price">Campaign Participation</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'SERVICE_PROVIDER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/service-order-rejected-detail">Rejected Orders</a>
                <a class="dropdown-item" *ngIf="
                  (loggedInUserType == 'SERVICE_PROVIDER') &&
                  SharingData &&
                  userRequestStatus == 'APPROVED'
                " routerLink="/service-campaign-list">Campaign Issued</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-product-enquiry">Product Enquiry</a>

                <a class="dropdown-item" *ngIf="
                      loggedInUserType == 'RETAILER' &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/campaign-list">Campaign Issued</a>


                <!-- <a class="dropdown-item" *ngIf="
                      loggedInUserType == 'RETAILER' &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/retailer-requested-price">Requested Price</a> -->
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-wallet">My Wallet</a>
                <a class="dropdown-item" *ngIf="
                    (loggedInUserType == 'CUSTOMER' ||
                      loggedInUserType == 'RETAILER') &&
                    SharingData &&
                    userRequestStatus == 'APPROVED'
                  " routerLink="/customer-address-list">Address</a>
                <a class="dropdown-item" (click)="openRoleModal()">Switch role</a>
                <a class="dropdown-item" *ngIf="subType != 'FIELD_ENTITY' &&isSubService"
                  (click)="navigateToRejected()">Rejected Order</a>
                <a class="dropdown-item" (click)="logOutModal()">Logout</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="border underline"></div> -->
      </div>
    </nav>
  </div>
</header>

<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg">
      <div class="d-flex justify-content-center mt-5 mb-4" id="book">
        <img class="for-img1" style="height: 100%; width: 18%" src="assets/latest/logoutui.png" alt="login form"
          class="img-fluid" />
      </div>
      <h2 class="modal-title d-flex justify-content-center okay" style="color: #263238">
        Are you sure you want <br />to logout?
      </h2>

      <div class="modal-body d-flex justify-content-center mb-4">
        <button type="button" class="btn btntt1" data-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btntt ml-3" data-dismiss="modal" routerLink="/login" (click)="logout()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>