<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mt-4 mb-5 topfooter">

  <div class="">


    <div class="row mt-3 mb-5">
      <div class="col-md-7 mt-4">
        <div class="row categoryimageView">
          <span>
            <img class="image-custom qwqwqw" [hidden]="!imageLoaded" (load)="onImageLoad()" [src]="imgurl" alt="" />
            <ngx-skeleton-loader *ngIf="!imageLoaded" [theme]="{
                width: '670px',
                height: '405px',
                position: 'relative'
              }" count="1"></ngx-skeleton-loader>
            <span class="heart" *ngIf="isLoggedIn" (click)="addWishlist(productDetails?._id)">
              <i class="fa fa-heart-o" *ngIf="productDetails?.isLike == false"></i>
              <i class="fa fa-heart" style="color: red" *ngIf="productDetails?.isLike == true"></i>
            </span>
            <span class="heart" *ngIf="!isLoggedIn" (click)="showMessage()" [routerLink]="['/login']">
              <i class="fa fa-heart-o" *ngIf="productDetails?.isLike == false"></i>
              <i class="fa fa-heart" style="color: red" *ngIf="productDetails?.isLike == true"></i>
            </span>
          </span>
        </div>
        <div class="d-flex mt-4" [ngClass]="productDetails?.productImage > 2 ? 'justify-content-between' : ''"
          *ngIf="productDetails?.productImage?.length > 1">
          <div *ngFor="let item of productDetails?.productImage ; let i = index" class="">
            <img class="custom-img h-100 change-img" style="cursor: pointer" [src]="item"
              (click)="changeProductimage(item)" />
          </div>
        </div>
      </div>
      <div class="col-md-5 p-0 digitalartBox">
        <div class="row">
          <div class="col-8 digitalartconBox">
            <p class="prdct-home">{{ productDetails?.productName }}</p>
          </div>
          <div class="col-4 digitalartconBox interest">
            <button class="btn btn-theme int" (click)="openModal()">Preferred Price</button>
          </div>
          <div class="col-12 digitalartconBox">
            <p class="para-text-common mb-0 digitalartconBox">
              Product ID : {{ productDetails?._id }}
            </p>
          </div>
          <div class="col-6 digitalartconBox">

            <ng-container *ngIf="!productDetails?.isCampaignActive">
              <div class="row justify-content-between" style="margin-right: 18px">
                <span [ngClass]="{ crossPrice: productDetails?.isDealActive }">
                  {{
                  mrp
                  ? getPrice(mrp | currency : "ZAR" : "R")
                  : productDetails?.priceSizeDetails[0]?.price &&
                  getPrice(
                  productDetails?.priceSizeDetails[0]?.price
                  | currency : "ZAR" : "R"
                  )
                  }}
                </span>
                <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                  {{
                  afterDiscountPrice
                  ? getPrice(afterDiscountPrice | currency : "ZAR" : "R")
                  : getPrice(afterDiscountPrice | currency : "ZAR" : "R")
                  }}
                </span>
                <span *ngIf="productDetails?.isDealActive" class="priceTestPColorD">
                  {{ productDetails?.dealDiscount }}% off
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="productDetails?.isCampaignActive">
              <ng-container *ngIf="iscampainValid[index]">
                <div class="row justify-content-between" style="margin-right: 18px">
                  <span class="crossPrice">
                    {{
                    mrp
                    ? getPrice(mrp | currency : "ZAR" : "R")
                    : productDetails?.priceSizeDetails[0]?.price &&
                    getPrice(
                    productDetails?.priceSizeDetails[0]?.price
                    | currency : "ZAR" : "R"
                    )
                    }}
                  </span>
                  <span class="priceTestPColor">
                    {{
                    getcampaignDiscountedPrice()?.discountedPrice
                    ? getPrice( getcampaignDiscountedPrice().discountedPrice | currency : "ZAR" : "R")
                    : getPrice( getcampaignDiscountedPrice().discountedPrice | currency : "ZAR" : "R")
                    }}
                  </span>
                  <span class="priceTestPColorD">
                    {{ getcampaignDiscountedPrice()?.discountedPercentage }}% off
                  </span>

                </div>
                <div class="col-12 post d-flex">
                  <label style="white-space: pre;margin: 4px;
                 ">Campaign Ends In </label>
                  <div class="timmer">
                    {{getCountDownTime(productDetails?.campainId?.endDate)}}
                  </div>
                </div>
              </ng-container>

              <div *ngIf="!iscampainValid[index]" class="row justify-content-between" style="margin-right: 18px">
                <span>
                  {{
                  mrp
                  ? getPrice(mrp | currency : "ZAR" : "R")
                  : productDetails?.priceSizeDetails[0]?.price &&
                  getPrice(
                  productDetails?.priceSizeDetails[0]?.price
                  | currency : "ZAR" : "R"
                  )
                  }}
                </span>

              </div>
            </ng-container>

          </div>
          <div class="bordernone col-12 mt-2 mb-2">
            <img src="assets/latest/veriticalline.svg" style="height: 2px; width: 100%" alt="" />
          </div>
        </div>
        <ng-container *ngIf="!isLoading">
          <app-table-loader></app-table-loader>
        </ng-container>
        <ng-container *ngIf="isLoading">
          <div class="" style="margin-top: 1em">
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Product Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{ productDetails?.productName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Category Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{ productDetails?.categoryId.categoryName }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Quantity Available</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{
                  isOutofStock(
                  quantity,
                  productDetails?.priceSizeDetails[0]?.quantity
                  )
                  }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Size/Value</p>
              </div>
              <div class="">:</div>
              <div class="col-7 flex-data">

                <ng-container *ngFor="let item of productDetails?.priceSizeDetails;let i = index">
                  <p class="pid-1 new-loop-para"
                    (click)="price(item?.weight, item.volume, item?.price, item?.quantity, item?.value, item?.id, item?.unit, i)"
                    [ngClass]="{ active: value == item?.value }">
                    {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                  </p>
                </ng-container>
              </div>
            </div>


          </div>

          <div class="mt-4 pt-3 descriptionBox">
            <span class="d-flex justify-content-between">
              <h1 class="text-center desc">Description</h1>
            </span>
            <p style="word-break: break-word" class="text-left descrip ml-3"
              [innerHTML]="rawText(productDetails?.description)">
              <!-- {{  }} -->
            </p>
          </div>

          <div class="ml-3 mt-3 addtocartBox">
            <button class="add-cart" *ngIf="isLoggedIn && quantity == 0" disabled>
              Out of stock
            </button>
            <button class="add-cart" *ngIf="isLoggedIn && quantity > 0 && flag" (click)="addToCart()">
              Add to Cart
            </button>
            <button class="add-cart" *ngIf="isLoggedIn && quantity > 0 && !flag" style="opacity: 0.75;">
              Loading..
            </button>
            <button class="add-cart" *ngIf="!isLoggedIn" (click)="showMessage()" [routerLink]="['/login']">
              Add to Cart
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- service slider -->
<div class="container mt-5 pt-5 likeBox" *ngIf="iaAPILoaded && serviceData?.length">
  <!-- <hr class="mt-3"> -->
  <div class="d-flex justify-content-start">
    <h1 class="heading-text-cat" style="color: #000000 !important; font-size: 22px !important">
      YOU MAY ALSO LIKE
    </h1>
  </div>
  <div class="items mt-4">
    <div class="slick_slider">
      <div class="service_slider slider d-flex slider-customize">

        <ng-container *ngIf="serviceData?.length">
          <ng-container *ngFor="let data of serviceData; let i = index">
            <div *ngIf="productDetails._id !== data._id">
              <product-card [data]="data" (on_Click)="viewProducts(data?._id)"></product-card>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!serviceData?.length">

          <div>
            <ng-container *ngFor="let data of length(5)">
              <product-card [data]="data" width="228px" height="300px"></product-card>
            </ng-container>
          </div>
        </ng-container>



      </div>
    </div>


  </div>
</div>

<div class="container mt-5 pt-5 likeBox" *ngIf="!iaAPILoaded">
  <!-- <hr class="mt-3"> -->
  <div class="d-flex justify-content-start">
    <h1 class="heading-text-cat" style="color: #000000 !important; font-size: 22px !important">
      YOU MAY ALSO LIKE
    </h1>
  </div>
  <div class="items mt-4">




    <div class="d-flex flex-wrap justify-content-between">
      <ng-container *ngFor="let data of length(5)">
        <product-card [data]="data" width="228px" height="300px"></product-card>
      </ng-container>
    </div>

  </div>
</div>
<div class="modal fade" id="interestedPriceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="height: 90px; background: #BF1E2E;">
        <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
            style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <!-- <span aria-hidden="true" style="color: white;">&times;</span> -->
        </button>
      </div>
      <div class="modal-body">
        <div class="form" *ngIf="showInterestedPriceModal">

          <div class="row justify-content-between">
            <div class="col-md-12 text-center">

              <img class="custom-img h-100 change-img" style="cursor: pointer; height: 200px !important;"
                [src]="productDetails?.productImage[0]" />

            </div>

            <div class="col-md-12 text-center">
              <div class="form-group d-flex">
                <label for="size" style="margin-top: 10px;">Size/Value :- </label>
                <ng-container *ngFor="let item of productDetails?.priceSizeDetails">
                  <p class="pid-1 new-loop-para"
                    (click)="price(item?.price, item?.quantity, item?.value, item?.id, item?.unit)"
                    [ngClass]="{ active: value == item?.value }">
                    {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                  </p>
                </ng-container>
              </div>
            </div>

            <div class="col-md-12 text-center">
              <div class="form-group d-flex">
                <div>Price :-</div>
                <div class="col-6 digitalartconBox">
                  <div class="row">
                    <span>
                      {{
                      mrp
                      ? getPrice(mrp | currency : "ZAR" : "R")
                      : productDetails?.priceSizeDetails[0]?.price &&
                      getPrice(
                      productDetails?.priceSizeDetails[0]?.price
                      | currency : "ZAR" : "R"
                      )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">

              <!-- <div class="form-group">
                <label for="exampleInputEmail1">Interested Price</label>
                <input type="text" class="form-control"  aria-describedby="toDate"
                  placeholder="Enter Interested Price"
                
                  (keypress)="commonService.numberOnly($event)"
                  (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)" 
                  onkeyup="if(this.value > 99) this.value = 99; if(this.value < 0) this.value = 0;"
                  >
              </div> -->
              <div class="form-group d-flex">
                <div>
                  <label for="exampleInputEmail1" style="margin-top: 10px;">Interested Price</label>
                </div>
                <div class="col-md-6">
                  <input type="text" [(ngModel)]="interestedPrice" aria-describedby="toDate"
                    placeholder="Enter Interested Price" (keypress)="commonService.numberOnly($event)"
                    (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)">
                  <div *ngIf="mrp < interestedPrice" style="color: #BF1E2E;">
                    Invalid Price
                  </div>
                </div>
                <!-- <div class="error" *ngIf="mrp">
                  <p >Can not add more amount than actual amount</p>
                </div> -->
              </div>
              <div class="form-group d-flex">
                <div>
                  <label for="exampleInputEmail1" style="margin-top: 10px;">Quantity</label>
                </div>
                <div class="col-md-6">
                  <input class="form-control" type="number" [(ngModel)]="intrestedQuantity"  (keypress)="commonService.numberOnly($event)"
                  (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)">
                  <div *ngIf="quantity < intrestedQuantity" style="color: #BF1E2E;">
                    Quantity should be equal or less than Available Quantity
                  </div>
                  <!-- <select class="form-control" [(ngModel)]="intrestedQuantity"> -->
                    <!-- <option value="">Select Quantity</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option> -->
                    <!-- <option [value]="i+1" *ngFor="let item of lengthOf(quantity);let i = index">{{item}}</option> -->
                  <!-- </select> -->
                </div>
                <!-- <div class="error" *ngIf="mrp">
                  <p >{{quantity}}</p>
                </div> -->
              </div>


            </div>

            <div class="form-group d-flex">
              <div>
                <input type="radio" (change)="spordicType = 'AUTOMATIC'" name="spordaicSelection" id="auto-spordaic"
                  [checked]="spordicType === 'AUTOMATIC'">
                <label for="auto-spordaic" style="margin-top: 10px;">Automatic : Buy Automatic when campaign is
                  started</label>
              </div>


            </div>
            <div class="form-group d-flex">

              <div>
                <input type="radio" (change)="spordicType = 'SEMIAUTOMATIC'" name="spordaicSelection" id="semi-spordaic"
                  [checked]="spordicType === 'SEMIAUTOMATIC'">
                <label for="semi-spordaic" style="margin-top: 10px;">Semi Automatic : Let me review the cart before
                  buy</label>
              </div>

            </div>
            <div class="form-group d-flex">

              <div>
                <input type="radio" (change)="spordicType = 'MANUAL'" name="spordaicSelection" id="manual"
                  [checked]="spordicType === 'MANUAL'">
                <label for="manual" style="margin-top: 10px;">Manual : Recieve notification when campaign is started
                  buy</label>
              </div>

            </div>


          </div>
          <!-- <div class="col-md-12 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Available Stock {{ campaignModel[selectedIndex].quantity - campaignModel[selectedIndex].releaseQuantity}}</label>
              </div>
            </div> -->

          <div class="col-md-12 text-center mt-3">
            <button class="btn btn-theme" (click)="confirmInterestedPrice()" [disabled]="mrp < interestedPrice || quantity < intrestedQuantity ||(interestedPrice && intrestedQuantity)==0 ||(interestedPrice && intrestedQuantity)==null ">Confirm
              Price</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>